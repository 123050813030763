@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
/* Setting up a root with css variables for all the colors being used */
:root {
  --dark-blue: #213368;
  --light-blue: #159cfe;
  --light-blue-accent: #1181d1;
  --white: #ffffff;
  --dark-gray: #686868;
  --light-gray: #f6f6f6;
  --input-gray: #e0e0e0;

  /* --black-accent: rgb(44, 44, 44); */
  --black-accent: rgb(44, 44, 44);
  --black: #000;

  --pill-border-radius: 100px;
}

/* Reset Styling */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  font-family: "Inter", sans-serif;
  font-family: "Open Sans", sans-serif;
}

body {
  background-color: #ffffff;
  background-color: var(--white);
  font-family: "Inter", sans-serif;
  font-family: "Open Sans", sans-serif;
}

input,
textarea {
  width: 100%;
  display: block;
  padding: 1rem;
  background-color: #ffffff;
  background-color: var(--white);
  border: none;
  outline: none;
  border-radius: 100px;
  border-radius: var(--pill-border-radius);
}
input::-webkit-input-placeholder {
  color: #686868;
  color: var(--dark-gray);
}
input:-ms-input-placeholder {
  color: #686868;
  color: var(--dark-gray);
}
input::placeholder {
  color: #686868;
  color: var(--dark-gray);
}

textarea {
  resize: none;
}

form {
  width: 100%;
}
a {
  text-decoration: none;
  font-weight: normal;
}
button.pill-btn {
  border-radius: 100px;
  border-radius: var(--pill-border-radius);
  border: none;
  outline: none;
  padding: 1rem 2rem;
  cursor: pointer;
}

button.disabled {
  background-color: #686868;
  background-color: var(--dark-gray);
  color: #ffffff;
  color: var(--white);
  cursor: unset;
}

button.clear-btn {
  margin: 1rem 1rem 1rem 0;
  color: white;
  background-color: rgb(44, 44, 44);
  background-color: var(--black-accent);
}
button.clear-btn:hover,
button.clear-btn:focus {
  background-color: black;
}
button.blue {
  background-color: #159cfe;
  background-color: var(--light-blue);
  color: #ffffff;
  color: var(--white);
}
button.blue:hover,
button.blue:focus {
  background-color: #1181d1;
  background-color: var(--light-blue-accent);
}

.container {
  width: 90%;
  max-width: 650px;
  margin: 0 auto;
  /* height: 90%; */
}

/* Sign In */
.log-in,
.sign-up {
  background-color: #213368;
  background-color: var(--dark-blue);
  width: 100%;
  height: 100vh;
}

.log-in-container,
.sign-up-container {
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.log-in-h1,
.sign-up-h1 {
  display: inline-block;
  color: #ffffff;
  color: var(--white);
  margin: 2rem 0;
  position: relative;
}
.log-in-container h1::after,
.sign-up-container h1::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  bottom: -10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #ffffff;
  background-color: var(--white);
}
.log-in-container input,
.sign-up-container input {
  margin: 0.5rem 0;
}

.log-in-container button,
.sign-up-container button {
  width: 100%;
  margin: 1rem 0;
}

/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++
user campaigns page
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
.campaigns-page {
  /* background-color: #e5e5e5; */
  background-color: #ffffff;
  background-color: var(--white);
}
.campaigns-header {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  padding: 2%;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.campaigns-header div p {
  color: rgba(164, 161, 161, 1);
}
.campaigns-header div h4 {
  font-size: 25px;
}
.ch-1 {
  flex-basis: 60%;
}
.campaigns-header button {
  padding: 1% 4% 1% 4%;
  border-radius: 50px;
  margin: 2rem 0.5rem;
  border: none;
  outline: none;
  font-size: 18px;
  color: white;
  background-color: #159cfe;
  cursor: pointer;
}

.all-your-campaigns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.campaign-grouping {
  /* box-shadow: 0px 1px 4px rgb(19 126 199 / 20%); */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  padding: 2%;
  margin: 2%;
}
.progress-group {
  display: flex;
  margin-bottom: 9%;
}

.pg-1 {
  width: 3rem;
  height: 3rem;
  margin-right: 0.4rem;
  text-align: center;
  border: 1px solid #159cfe;
  border-radius: 100%;
  color: rgba(255, 255, 255, 1);
  background-color: #159cfe;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pg-2 {
  margin-right: 2%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.pg-2-2 {
  color: rgba(164, 161, 161, 1);
}
.pg-2-3 {
  font-weight: bold;
}
.pg-3 {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* buttons */
.c-button-g {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 6%;
  font-weight: bold;
  color: rgba(33, 51, 104, 1);
  padding: 2%;
  cursor: pointer;
}
.sc-buttons {
  /* padding-bottom: 3%; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  /* color: rgba(33, 51, 104, 1); */
  color: #213368;
  color: var(--dark-blue);
  flex-wrap: wrap;
}
.cb {
  padding: 1% 4% 1% 4%;
  border-radius: 50px;
  margin: 0.5rem;
  border: none;
  outline: none;
  font-size: 18px;
  color: white;
  cursor: pointer;
}
.cb-1 {
  background-color: #159cfe;
}
.cb-2 {
  background-color: transparent;
  border: 1px solid;
  color: rgb(44, 44, 44);
  color: var(--black-accent);
}
.c-progress-line {
  background-color: #f1f1f1;
  border-radius: 50px;
}
.c-progress-line-1 {
  background-color: #159cfe;
  height: 3px;
  /* width: 80%; */
}

/* ///////////////////////////////////////////////////// 
Show campaign route and donate
////////////////////////////////////////////////////*/

.sh-group {
  display: flex;
  flex-direction: column;
  padding: 2rem;
}
/* .sh-header,
.sc-buttons {
  background-color: var(--input-gray);
} */
.sh-header {
  /* margin-top: 2%; */
  margin: 1.5rem 0 2.5rem 0;
  /* padding: 2%; */
  font-size: 1.5rem;
  text-align: center;
  color: #213368;
  color: var(--dark-blue);
}
.donate-and-campaign {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 800px) {
  .donate-and-campaign {
    flex-direction: column;
  }
  .donate {
    margin-top: 2rem;
  }
}
.donate {
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  color: #686868;
  color: var(--dark-gray);
  padding: 2%;
  justify-content: center;
}
.donate h3 {
  text-align: center;
  margin-bottom: 8%;
}
.choose-donation {
  background-color: white;
  box-shadow: 1px 1px 1px 0.1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 2% 0% 2% 0%;
  margin-bottom: 3%;
  display: flex;
  color: #686868;
  color: var(--dark-gray);
  justify-content: space-around;
  align-items: center;
}
.your-donation-count {
  display: flex;
}
.donation-goal,
.donation-count {
  font-weight: bold;
  color: #000;
}
.donation-count {
  margin: 4%;
  padding-left: 4%;
  padding-right: 4%;
}
.donation-name h4,
.your-donation h4 {
  margin-bottom: 7%;
}
.your-donation h4 {
  color: #159cfe;
}
.donate-btn {
  border: 1px solid;
  border-radius: 5px;
  width: 2rem;
  height: 2rem;
  font-size: 1.2rem;
  color: #686868;
  color: var(--dark-gray);
}
.donate-btn-plus {
  border: none;
  background-color: #159cfe;
  background-color: var(--light-blue);
  color: white;
}
.donate-button {
  padding: 2% 4% 2% 4%;
  margin: 7%;
  border-radius: 50px;
  border: none;
  outline: none;
  font-size: 18px;
  color: white;
  background-color: #159cfe;
  background-color: var(--light-blue);
}
.donate-button:hover,
.donate-btn-plus:hover {
  background-color: #1181d1;
  background-color: var(--light-blue-accent);
}

.sh-details-group {
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  color: #686868;
  color: var(--dark-gray);
  /* border-right: 1px solid rgb(236, 236, 236); */
}
@media screen and (max-width: 800px) {
  .sh-details-group {
    flex-direction: column;
  }
}
.sh-desc {
  font-size: 1.2rem;
  margin: 2rem 0;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-family: "Open Sans", sans-serif;
}
.sh-desc h3 {
  margin: 1%;
  text-align: center;
}
.sh-desc p {
  /* max-width: 50ch; */
  text-align: center;
  font-family: "Inter", sans-serif;
  font-family: "Open Sans", sans-serif;
}
.sh-details {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.sh-table {
  border-collapse: collapse;
  width: 80%;
}
.sh-table-2 {
  border-collapse: collapse;
  width: 100%;
  margin: 5%;
}

.sh-table td,
.sh-table th {
  padding: 8px;
  text-align: center;
}

.sh-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

.table-group {
  width: 100%;
  /* padding: 2%; */
  /* border: 1px solid var(--black-accent); */
  border-radius: 5px;
}

.table-group > .row {
  display: grid;
  text-align: center;
  grid-template-columns: 0.5fr 1.5fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  gap: 1rem;
  /* margin: 1rem 0; */
  padding: 0.5rem;
  text-align: center;
  background-color: #f6f6f6;
  background-color: var(--light-gray);
  color: rgb(44, 44, 44);
  color: var(--black-accent);
}
.table-group > .header-row {
  /* margin: 1rem 0 2rem 0; */
  /* color: var(--black-accent); */
  background-color: #213368;
  color: white;
  border-radius: 5px 5px 0 0;
  font-weight: 500;
  /* font-size: 1.2rem; */
}

@media screen and (max-width: 500px) {
  .table-group,
  .table-group-donations {
    font-size: 10px;
  }
  .table-group > .header-row,
  .table-group-donations > .header-row {
    font-size: 0.6rem;
  }
}

.table-group-2 {
  width: 100%;
  margin: 2rem 0;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1.5rem;
  -webkit-column-gap: 1.5rem;
          column-gap: 1.5rem;
}

.table-group-2 > div {
  margin: 1rem 0;
  /* padding: 1rem; */
  background-color: #f6f6f6;
  background-color: var(--light-gray);
  border-radius: 5px;
  color: rgb(44, 44, 44);
  color: var(--black-accent);
}
.table-group-2 > div > p {
  padding: 0.5rem;
}

.table-group-2 .header {
  /* color: var(--black-accent); */
  background-color: #213368;
  color: white;
  border-radius: 5px 5px 0 0;
  font-weight: 500;
  /* font-size: 1.2rem; */
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 500px) {
  .table-group-2 {
    grid-template-columns: 1fr;
  }
}

.table-group-3 {
  width: 100%;
  margin: 2rem 0;
  display: flex;
}

/* .sh-details {
  padding: 2%;
}
.sh-details h3 {
  text-align: center;
  margin: 1%;
} */

.share-1 {
  /* margin: 0.5rem 0; */
  color: #213368;
  color: var(--dark-blue);
  font-weight: bold;
}
.share-2 {
  margin: 1rem 0;
}
.share-3:active {
  background-color: #213368;
  color: white;
}
.share-4 {
  border: 1px solid;
  padding: 1%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 1%;
  cursor: pointer;
}
/* .social-share{
  color: #213368;
  border: 1px solid;
  display: flex;
  justify-content: space-around;
  padding: 2%;
} */

/* ///////////////////////////////////////////////////// 
Register Campaign
////////////////////////////////////////////////////*/
* {
  font-family: Arial, Helvetica, sans-serif;
}
.create-campaign-section {
  background-color: #ffffff;
  background-color: var(--white);
  padding-bottom: 4rem;
}

.create-campaign-header,
.age-native-label-placeholder {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 2rem 0;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 4rem;
}
.create-campaign-header > * {
  margin: 0.5rem 0.5rem;
}
.create-campaign-header::after {
  content: "";
  position: absolute;
  display: block;
  background-color: #159cfe;
  background-color: var(--light-blue);
  bottom: 0;
  left: 50%;
  height: 4px;
  width: 10%;
  border-radius: 4px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.input-div {
  width: 100%;
  margin: 1.5rem 0;
}

.input-div label {
  /* color: var(--dark-blue); */
  font-weight: 500;
}
.campaign-input {
  border-radius: 5px;
  background-color: #e0e0e0;
  background-color: var(--input-gray);
}

.registry-div {
  margin: 4rem 0;
}
.registry-div h6 {
  font-size: 1.2rem;
  text-align: center;
}

.registry-input-div {
  margin: 1rem auto;
  padding: 1rem 0;
  /* width: 100%;s */
}

.registry-input {
  background-color: #e0e0e0;
  background-color: var(--input-gray);
  /* border: 1px solid var(--dark-gray); */
  color: #000;
  color: var(--black);
  border-radius: 5px;
  padding: 0.7rem;
}
.registry-input::-webkit-input-placeholder {
  color: #686868;
  color: var(--dark-gray);
}
.registry-input:-ms-input-placeholder {
  color: #686868;
  color: var(--dark-gray);
}
.registry-input::placeholder {
  color: #686868;
  color: var(--dark-gray);
}

.btn-add-item {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: rgba(21, 156, 254, 1);
  border: none;
  border-radius: 5px;
  color: white;
}
.btn-add-item:hover {
  background-color: #1181d1;
  background-color: var(--light-blue-accent);
}

.registry-display {
  width: 100%;
  /* background-color: red; */
}
.registry-display > .row {
  background-color: #f6f6f6;
  background-color: var(--light-gray);
  display: grid;
  grid-template-columns: 5fr 3fr 2fr;
  grid-gap: 10px;
  gap: 10px;
  padding: 0.5rem;
  /* border-left: 1px solid var(--dark-gray);
  border-right: 1px solid var(--dark-gray); */
  /* border-bottom: 1px solid var(--dark-gray); */
}
.registry-display > .header-row {
  background-color: #213368;
  color: white;
  border-radius: 5px 5px 0 0;
  /* border-left: 1px solid var(--dark-blue);
  border-right: 1px solid var(--dark-blue);
  border-top: 1px solid var(--dark-blue); */
}

.col-quan,
.col-name {
  display: flex;
  align-items: center;
}
.delete-item {
  width: 100%;
  font-size: 1rem;
  padding: 0.3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--black-accent); */
  /* color: var(--white); */
  border: none;
  color: red;
  border-radius: 100px;
  font-weight: 600;
  cursor: pointer;
}

/* LANDING PAGE CSS START */

/* body {
  background: #213368;
  color: white;
} */

.landing_logo {
  height: 200px;
  /* width: 300px; */
  border-radius: 100px;
  /* margin-left: 0px;
    margin-right: 10px;
    margin-left: -9px;
    z-index: -99; */
  /* height: 170px;
  border-radius: 100%;
  background: white;
  border: solid 1px rgb(71, 61, 61)(71, 61, 61); */
}

.heading {
  height: 18px;
  bottom: 236px;
  color: rgb(255, 255, 255, 1);
  /* font-family: Ubuntu; */
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 0.01px;
  text-align: center;
}

.page-title {
  background: #213368;
  color: white;
  padding: 4rem 0;
  top: 36px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

@media screen and (max-height: 600px) {
  .page-title {
    height: unset;
  }
}
@media screen and (max-height: 500px) {
  .log-in,
  .sign-up {
    height: unset;
  }
}

.signIn-btn {
  margin-top: 50px;
  height: 51px;
  width: 328px;
  left: 16px;
  border-radius: 100px;
  padding: 16px, 32px, 16px, 32px;
  background: rgba(21, 156, 254, 1);
  border: 2px solid rgba(33, 51, 104, 1);
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  /* font-family: Montserrat; */
  line-height: 22px;
}

.signUp-btn {
  height: 51px;
  width: 328px;
  left: 16px;
  border-radius: 100px;
  padding: 16px, 32px, 16px, 32px;
  background: rgba(255, 255, 255, 1);
  border: 2px solid rgba(33, 51, 104, 1);
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  /* font-family: Montserrat; */
  line-height: 22px;
}

.learn_more {
  color: white;
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  text-decoration: underline;
}
/* LANDING PAGE CSS END */

/* GET STARTED CSS BEIGIN */

.get_started_header {
  background: rgba(33, 51, 104, 1);
  color: white;

  display: flex;
  align-items: center;

  height: 61px;

  /* font-family: Ubuntu; */
  font-size: 22px;
  font-style: normal;
  letter-spacing: em;
}

.get_started_logo {
  height: 700px;
  width: 90px;
  border-radius: 100px;
  margin-left: 0px;
  margin-right: 10px;
  margin-left: -9px;
}
.how-it-works {
  margin-top: 30px;
  text-align: center;
  margin-left: 44px;
  color: rgba(33, 51, 104, 1);
  /* font-family: Montserrat; */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
}

.get_started {
  /* text-align: center; */
  /* margin-left: 100px; */
  margin: 0px auto;
}

.Get-started-group {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  /* height: 90vh; */
  /* justify-content: center; */
  /* align-items: center; */
  text-align: center;
}
.get_started {
  /* text-align: center; */
  color: #213368;
  /* font-family: Montserrat; */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  /* display: flex; */
  /* margin-left: 15%; */
}

.get_started_btn {
  margin-top: 4rem;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0em;
  color: #ffffff;

  /* border: 1px solid rgba(33, 51, 104, 1); */
  border-radius: 100%;

  height: 50px;
  width: 328px;
  left: 16px;
  border-radius: 100px;
  padding: 16px, 32px, 16px, 32px;
  background: #159cfe;
  border: 1px solid #159cfe;
  font-size: 18px;
  text-align: center;
  /* font-family: Montserrat; */
  line-height: 22px;
}

@media screen and (max-width: 900px) {
  .get_started {
    margin-left: 50px;
  }
  /* .how-it-works {
    margin-left: 4px;
  } */
  /* .get_started_btn {
    margin-left: 25%;
  } */
}

/* Preview Styling */
.preview-data {
  padding: 2rem;
}

/* Donations styles on the show campaign page */
.donations-h2 {
  margin-top: 2rem;
}

.table-group-donations {
  width: 100%;
  border-radius: 5px;
}

.table-group-donations > .row {
  display: grid;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
  gap: 1rem;
  /* margin: 1rem 0; */
  padding: 0.5rem;
  text-align: center;
  background-color: #f6f6f6;
  background-color: var(--light-gray);
  color: rgb(44, 44, 44);
  color: var(--black-accent);
}

.table-group-donations > .header-row {
  background-color: #1181d1;
  background-color: var(--light-blue-accent);
  color: white;
  border-radius: 5px 5px 0 0;
  font-weight: 500;
  /* font-size: 1.2rem; */
}
button.btn-back {
  border: none;
  outline: none;
  color: #ffffff;
  color: var(--white);
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;
  margin: 0 1rem 0 auto;
}

.modal-bg {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal {
  margin: 2rem auto;
  width: 90%;
  max-width: 500px;
  background-color: #ffffff;
  background-color: var(--white);
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.modal h1 {
  margin-bottom: 2rem;
}

@media screen and (max-width: 700px) {
  button.btn-back {
    display: none;
  }
}


/* Tracker Styles */
.col {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .col-tracker {
  background-color: var(--light-blue-accent);
  color: var(--white);
  padding: 0.5rem;
  border-radius: var(--pill-border-radius);
} */

.col-input {
  width: 100%;
  max-width: 270px;
  display: block;
  padding: 0.5rem;
  background-color: #ffffff;
  background-color: var(--white);
  border: 2px solid #1181d1;
  border: 2px solid var(--light-blue-accent);
  outline: none;
  border-radius: 100px;
  border-radius: var(--pill-border-radius);
}

.track-btn {
  border: none;
  border-radius: 100px;
  border-radius: var(--pill-border-radius);
  outline: none;
  cursor: pointer;

  background-color: #159cfe;

  background-color: var(--light-blue);
  color: #ffffff;
  color: var(--white);
  padding: 0.5rem 2rem;
}
